<template>
  <div>

    <floating-menu :event-id="eventId"></floating-menu>

    <b-card v-if="isLoading && !fetchComplete" class="text-center">
        <b-spinner>Tunggu...</b-spinner>
    </b-card>
    
    <b-card v-if="!isLoading && fetchComplete && isEdit">
      <event-question-wheel-of-names :event-id="eventId" class="mt-2 pt-75"/>
    </b-card>

    <b-card v-if="!isLoading && fetchComplete && isEdit">
      <event-question-response-content :event-id="eventId" class="mt-2 pt-75"/>
    </b-card>

    <b-card v-if="!isLoading && fetchComplete">
      <event-question-add-content :event-id="eventId" :isEdit="isEdit" class="mt-2 pt-75"/>
    </b-card>

   
  </div>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink, BSpinner
} from 'bootstrap-vue'
import EventQuestionAddContent from './EventQuestionAddContent.vue'
import EventQuestionResponseContent from './EventQuestionResponseContent.vue'
import EventQuestionWheelOfNames from './EventQuestionWheelOfNames.vue'
import FloatingMenu from './FloatingMenu.vue'
import router from '@/router'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BSpinner,
    EventQuestionAddContent,
    EventQuestionResponseContent,
    EventQuestionWheelOfNames,
    FloatingMenu
  },
  data() {
    return {
      eventId: router.currentRoute.params.id,
      isEdit: false,
      isLoading: false,
      fetchComplete: false,
    }
  },
  methods: {
    fetchData() {
      this.isLoading = true

      this.$http.post('/admin/v1/feedback_questions_single', {
        event_id: this.eventId,
        event_booth_id: null
      })
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data

            if(currentData.questions.length > 0) {
                this.isEdit = true
              }
          }

          this.fetchComplete = true
          this.isLoading = false
       })
    }
  },
  created() {
    this.fetchData()

  }
}
</script>